import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Label, Button, CardBody, CardHeader, FormGroup, InputGroup, InputGroupText, Input, CardTitle } from 'reactstrap';
import webservice from '../../../Service/webservice';
import * as Yup from 'yup';
import { AesDecrypt, AesEncrypt } from '../../../Service/crypto';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs';
import { ErrorMessage, Field, FieldArray, Form, Formik, FormikHelpers } from 'formik';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { Eye, EyeOff, Plus, X } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useAppDispatch } from '../../../ReaduxToolkit/Hooks';

import { get_vendorname } from "./store"

interface Option {
  label: string;
  value: string;
}

interface FormValues {
  Website: string;
  Groups: string;
  MobileNo?: string;
  State: string;
  CompanyName: string;
  Address: any;
  City: string;
  Currency: string;
  ZipCode: string;
  Country: string;
  FirstName: string,
  Companytype: string[],
  LastName: string,
  Position: string,
  Email: string,
  Phone: string,
  Direction: string,
  Password: string,
  allocation_vendor_name: any,
  Providers: {
    firstName: string;
    middleName: string;
    lastName: string;
    providerId: string;
    providertaxId: string;
  }[];
  Group: {
    providerId: string;
  }[];
}

interface VendorOption {
  label: string;
  value: string;
}



const CountryOptions = [
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AX', label: 'Aland Islands' },
  { value: 'AL', label: 'Albania' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarctica' },
  { value: 'AG', label: 'Antigua and Barbuda' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AU', label: 'Australia' },
  { value: 'AT', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BY', label: 'Belarus' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BO', label: 'Bolivia' },
  { value: 'BA', label: 'Bosnia and Herzegovina' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BV', label: 'Bouvet Island' },
  { value: 'BR', label: 'Brazil' },
  { value: 'IO', label: 'British Indian Ocean Territory' },
  { value: 'BN', label: 'Brunei Darussalam' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'CV', label: 'Cabo Verde' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'CM', label: 'Cameroon' },
  { value: 'CA', label: 'Canada' },
  { value: 'KY', label: 'Cayman Islands' },
  { value: 'CF', label: 'Central African Republic' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CX', label: 'Christmas Island' },
  { value: 'CC', label: 'Cocos (Keeling) Islands' },
  { value: 'CO', label: 'Colombia' },
  { value: 'KM', label: 'Comoros' },
  { value: 'CG', label: 'Congo' },
  { value: 'CD', label: 'Congo, Democratic Republic of the' },
  { value: 'CK', label: 'Cook Islands' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'HR', label: 'Croatia' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CW', label: 'Curaçao' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czech Republic' },
  { value: 'DK', label: 'Denmark' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egypt' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'GQ', label: 'Equatorial Guinea' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'EE', label: 'Estonia' },
  { value: 'SZ', label: 'Eswatini' },
  { value: 'ET', label: 'Ethiopia' },
  { value: 'FK', label: 'Falkland Islands (Malvinas)' },
  { value: 'FO', label: 'Faroe Islands' },
  { value: 'FJ', label: 'Fiji' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'GF', label: 'French Guiana' },
  { value: 'PF', label: 'French Polynesia' },
  { value: 'TF', label: 'French Southern Territories' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgia' },
  { value: 'DE', label: 'Germany' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GR', label: 'Greece' },
  { value: 'GL', label: 'Greenland' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HM', label: 'Heard Island and McDonald Islands' },
  { value: 'VA', label: 'Holy See' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IR', label: 'Iran' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IM', label: 'Isle of Man' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italy' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japan' },
  { value: 'JE', label: 'Jersey' },
  { value: 'JO', label: 'Jordan' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KP', label: 'Korea (North)' },
  { value: 'KR', label: 'Korea (South)' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KG', label: 'Kyrgyzstan' },
  { value: 'LA', label: 'Laos' },
  { value: 'LV', label: 'Latvia' },
  { value: 'LB', label: 'Lebanon' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libya' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'MO', label: 'Macao' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Maldives' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MX', label: 'Mexico' },
  { value: 'FM', label: 'Micronesia (Federated States of)' },
  { value: 'MD', label: 'Moldova' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MA', label: 'Morocco' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'NC', label: 'New Caledonia' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NU', label: 'Niue' },
  { value: 'NF', label: 'Norfolk Island' },
  { value: 'MK', label: 'North Macedonia' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'NO', label: 'Norway' },
  { value: 'OM', label: 'Oman' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PW', label: 'Palau' },
  { value: 'PS', label: 'Palestine, State of' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papua New Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PN', label: 'Pitcairn' },
  { value: 'PL', label: 'Poland' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'QA', label: 'Qatar' },
  { value: 'RE', label: 'Réunion' },
  { value: 'RO', label: 'Romania' },
  { value: 'RU', label: 'Russian Federation' },
  { value: 'RW', label: 'Rwanda' },
  { value: 'BL', label: 'Saint Barthélemy' },
  { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha' },
  { value: 'KN', label: 'Saint Kitts and Nevis' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'MF', label: 'Saint Martin (French part)' },
  { value: 'PM', label: 'Saint Pierre and Miquelon' },
  { value: 'VC', label: 'Saint Vincent and the Grenadines' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'ST', label: 'Sao Tome and Principe' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbia' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SX', label: 'Sint Maarten (Dutch part)' },
  { value: 'SK', label: 'Slovakia' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SB', label: 'Solomon Islands' },
  { value: 'SO', label: 'Somalia' },
  { value: 'ZA', label: 'South Africa' },
  { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
  { value: 'SS', label: 'South Sudan' },
  { value: 'ES', label: 'Spain' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SJ', label: 'Svalbard and Jan Mayen' },
  { value: 'SE', label: 'Sweden' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'SY', label: 'Syrian Arab Republic' },
  { value: 'TW', label: 'Taiwan' },
  { value: 'TJ', label: 'Tajikistan' },
  { value: 'TZ', label: 'Tanzania' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TL', label: 'Timor-Leste' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad and Tobago' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TC', label: 'Turks and Caicos Islands' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'US', label: 'United States of America' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VE', label: 'Venezuela' },
  { value: 'VN', label: 'Viet Nam' },
  { value: 'VG', label: 'Virgin Islands (British)' },
  { value: 'VI', label: 'Virgin Islands (U.S.)' },
  { value: 'WF', label: 'Wallis and Futuna' },
  { value: 'EH', label: 'Western Sahara' },
  { value: 'YE', label: 'Yemen' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' },
];

const CurrencyOptions = [
  { value: 'AED', label: 'United Arab Emirates Dirham (AED)' },
  { value: 'AFN', label: 'Afghan Afghani (AFN)' },
  { value: 'ALL', label: 'Albanian Lek (ALL)' },
  { value: 'AMD', label: 'Armenian Dram (AMD)' },
  { value: 'ANG', label: 'Netherlands Antillean Guilder (ANG)' },
  { value: 'AOA', label: 'Angolan Kwanza (AOA)' },
  { value: 'ARS', label: 'Argentine Peso (ARS)' },
  { value: 'AUD', label: 'Australian Dollar (AUD)' },
  { value: 'AWG', label: 'Aruban Florin (AWG)' },
  { value: 'AZN', label: 'Azerbaijani Manat (AZN)' },
  { value: 'BAM', label: 'Bosnia-Herzegovina Convertible Mark (BAM)' },
  { value: 'BBD', label: 'Barbadian Dollar (BBD)' },
  { value: 'BDT', label: 'Bangladeshi Taka (BDT)' },
  { value: 'BGN', label: 'Bulgarian Lev (BGN)' },
  { value: 'BHD', label: 'Bahraini Dinar (BHD)' },
  { value: 'BIF', label: 'Burundian Franc (BIF)' },
  { value: 'BMD', label: 'Bermudian Dollar (BMD)' },
  { value: 'BND', label: 'Brunei Dollar (BND)' },
  { value: 'BOB', label: 'Bolivian Boliviano (BOB)' },
  { value: 'BRL', label: 'Brazilian Real (BRL)' },
  { value: 'BSD', label: 'Bahamian Dollar (BSD)' },
  { value: 'BTN', label: 'Bhutanese Ngultrum (BTN)' },
  { value: 'BWP', label: 'Botswana Pula (BWP)' },
  { value: 'BYN', label: 'Belarusian Ruble (BYN)' },
  { value: 'BZD', label: 'Belize Dollar (BZD)' },
  { value: 'CAD', label: 'Canadian Dollar (CAD)' },
  { value: 'CDF', label: 'Congolese Franc (CDF)' },
  { value: 'CHF', label: 'Swiss Franc (CHF)' },
  { value: 'CLP', label: 'Chilean Peso (CLP)' },
  { value: 'CNY', label: 'Chinese Yuan (CNY)' },
  { value: 'COP', label: 'Colombian Peso (COP)' },
  { value: 'CRC', label: 'Costa Rican Colón (CRC)' },
  { value: 'CUP', label: 'Cuban Peso (CUP)' },
  { value: 'CVE', label: 'Cape Verdean Escudo (CVE)' },
  { value: 'CZK', label: 'Czech Koruna (CZK)' },
  { value: 'DJF', label: 'Djiboutian Franc (DJF)' },
  { value: 'DKK', label: 'Danish Krone (DKK)' },
  { value: 'DOP', label: 'Dominican Peso (DOP)' },
  { value: 'DZD', label: 'Algerian Dinar (DZD)' },
  { value: 'EGP', label: 'Egyptian Pound (EGP)' },
  { value: 'ERN', label: 'Eritrean Nakfa (ERN)' },
  { value: 'ETB', label: 'Ethiopian Birr (ETB)' },
  { value: 'EUR', label: 'Euro (EUR)' },
  { value: 'FJD', label: 'Fijian Dollar (FJD)' },
  { value: 'FKP', label: 'Falkland Islands Pound (FKP)' },
  { value: 'FOK', label: 'Faroese Króna (FOK)' },
  { value: 'GBP', label: 'British Pound (GBP)' },
  { value: 'GEL', label: 'Georgian Lari (GEL)' },
  { value: 'GGP', label: 'Guernsey Pound (GGP)' },
  { value: 'GHS', label: 'Ghanaian Cedi (GHS)' },
  { value: 'GIP', label: 'Gibraltar Pound (GIP)' },
  { value: 'GMD', label: 'Gambian Dalasi (GMD)' },
  { value: 'GNF', label: 'Guinean Franc (GNF)' },
  { value: 'GTQ', label: 'Guatemalan Quetzal (GTQ)' },
  { value: 'GYD', label: 'Guyanese Dollar (GYD)' },
  { value: 'HKD', label: 'Hong Kong Dollar (HKD)' },
  { value: 'HNL', label: 'Honduran Lempira (HNL)' },
  { value: 'HRK', label: 'Croatian Kuna (HRK)' },
  { value: 'HTG', label: 'Haitian Gourde (HTG)' },
  { value: 'HUF', label: 'Hungarian Forint (HUF)' },
  { value: 'IDR', label: 'Indonesian Rupiah (IDR)' },
  { value: 'ILS', label: 'Israeli New Shekel (ILS)' },
  { value: 'IMP', label: 'Isle of Man Pound (IMP)' },
  { value: 'INR', label: 'Indian Rupee (INR)' },
  { value: 'IQD', label: 'Iraqi Dinar (IQD)' },
  { value: 'IRR', label: 'Iranian Rial (IRR)' },
  { value: 'ISK', label: 'Icelandic Króna (ISK)' },
  { value: 'JEP', label: 'Jersey Pound (JEP)' },
  { value: 'JMD', label: 'Jamaican Dollar (JMD)' },
  { value: 'JOD', label: 'Jordanian Dinar (JOD)' },
  { value: 'JPY', label: 'Japanese Yen (JPY)' },
  { value: 'KES', label: 'Kenyan Shilling (KES)' },
  { value: 'KGS', label: 'Kyrgyzstani Som (KGS)' },
  { value: 'KHR', label: 'Cambodian Riel (KHR)' },
  { value: 'KID', label: 'Kiribati Dollar (KID)' },
  { value: 'KMF', label: 'Comorian Franc (KMF)' },
  { value: 'KRW', label: 'South Korean Won (KRW)' },
  { value: 'KWD', label: 'Kuwaiti Dinar (KWD)' },
  { value: 'KYD', label: 'Cayman Islands Dollar (KYD)' },
  { value: 'KZT', label: 'Kazakhstani Tenge (KZT)' },
  { value: 'LAK', label: 'Lao Kip (LAK)' },
  { value: 'LBP', label: 'Lebanese Pound (LBP)' },
  { value: 'LKR', label: 'Sri Lankan Rupee (LKR)' },
  { value: 'LRD', label: 'Liberian Dollar (LRD)' },
  { value: 'LSL', label: 'Lesotho Loti (LSL)' },
  { value: 'LYD', label: 'Libyan Dinar (LYD)' },
  { value: 'MAD', label: 'Moroccan Dirham (MAD)' },
  { value: 'MDL', label: 'Moldovan Leu (MDL)' },
  { value: 'MGA', label: 'Malagasy Ariary (MGA)' },
  { value: 'MKD', label: 'Macedonian Denar (MKD)' },
  { value: 'MMK', label: 'Burmese Kyat (MMK)' },
  { value: 'MNT', label: 'Mongolian Tögrög (MNT)' },
  { value: 'MOP', label: 'Macanese Pataca (MOP)' },
  { value: 'MRU', label: 'Mauritanian Ouguiya (MRU)' },
  { value: 'MUR', label: 'Mauritian Rupee (MUR)' },
  { value: 'MVR', label: 'Maldivian Rufiyaa (MVR)' },
  { value: 'MWK', label: 'Malawian Kwacha (MWK)' },
  { value: 'MXN', label: 'Mexican Peso (MXN)' },
  { value: 'MYR', label: 'Malaysian Ringgit (MYR)' },
  { value: 'MZN', label: 'Mozambican Metical (MZN)' },
  { value: 'NAD', label: 'Namibian Dollar (NAD)' },
  { value: 'NGN', label: 'Nigerian Naira (NGN)' },
  { value: 'NIO', label: 'Nicaraguan Córdoba (NIO)' },
  { value: 'NOK', label: 'Norwegian Krone (NOK)' },
  { value: 'NPR', label: 'Nepalese Rupee (NPR)' },
  { value: 'NZD', label: 'New Zealand Dollar (NZD)' },
  { value: 'OMR', label: 'Omani Rial (OMR)' },
  { value: 'PAB', label: 'Panamanian Balboa (PAB)' },
  { value: 'PEN', label: 'Peruvian Sol (PEN)' },
  { value: 'PGK', label: 'Papua New Guinean Kina (PGK)' },
  { value: 'PHP', label: 'Philippine Peso (PHP)' },
  { value: 'PKR', label: 'Pakistani Rupee (PKR)' },
  { value: 'PLN', label: 'Polish Złoty (PLN)' },
  { value: 'PYG', label: 'Paraguayan Guaraní (PYG)' },
  { value: 'QAR', label: 'Qatari Riyal (QAR)' },
  { value: 'RON', label: 'Romanian Leu (RON)' },
  { value: 'RSD', label: 'Serbian Dinar (RSD)' },
  { value: 'RUB', label: 'Russian Ruble (RUB)' },
  { value: 'RWF', label: 'Rwandan Franc (RWF)' },
  { value: 'SAR', label: 'Saudi Riyal (SAR)' },
  { value: 'SBD', label: 'Solomon Islands Dollar (SBD)' },
  { value: 'SCR', label: 'Seychellois Rupee (SCR)' },
  { value: 'SDG', label: 'Sudanese Pound (SDG)' },
  { value: 'SEK', label: 'Swedish Krona (SEK)' },
  { value: 'SGD', label: 'Singapore Dollar (SGD)' },
  { value: 'SHP', label: 'Saint Helena Pound (SHP)' },
  { value: 'SLL', label: 'Sierra Leonean Leone (SLL)' },
  { value: 'SOS', label: 'Somali Shilling (SOS)' },
  { value: 'SRD', label: 'Surinamese Dollar (SRD)' },
  { value: 'SSP', label: 'South Sudanese Pound (SSP)' },
  { value: 'STN', label: 'São Tomé and Príncipe Dobra (STN)' },
  { value: 'SYP', label: 'Syrian Pound (SYP)' },
  { value: 'SZL', label: 'Eswatini Lilangeni (SZL)' },
  { value: 'THB', label: 'Thai Baht (THB)' },
  { value: 'TJS', label: 'Tajikistani Somoni (TJS)' },
  { value: 'TMT', label: 'Turkmenistani Manat (TMT)' },
  { value: 'TND', label: 'Tunisian Dinar (TND)' },
  { value: 'TOP', label: 'Tongan Paʻanga (TOP)' },
  { value: 'TRY', label: 'Turkish Lira (TRY)' },
  { value: 'TTD', label: 'Trinidad and Tobago Dollar (TTD)' },
  { value: 'TVD', label: 'Tuvaluan Dollar (TVD)' },
  { value: 'TWD', label: 'New Taiwan Dollar (TWD)' },
  { value: 'TZS', label: 'Tanzanian Shilling (TZS)' },
  { value: 'UAH', label: 'Ukrainian Hryvnia (UAH)' },
  { value: 'UGX', label: 'Ugandan Shilling (UGX)' },
  { value: 'USD', label: 'United States Dollar (USD)' },
  { value: 'UYU', label: 'Uruguayan Peso (UYU)' },
  { value: 'UZS', label: 'Uzbekistani Som (UZS)' },
  { value: 'VES', label: 'Venezuelan Bolívar Soberano (VES)' },
  { value: 'VND', label: 'Vietnamese Đồng (VND)' },
  { value: 'VUV', label: 'Vanuatu Vatu (VUV)' },
  { value: 'WST', label: 'Samoan Tālā (WST)' },
  { value: 'XAF', label: 'Central African CFA Franc (XAF)' },
  { value: 'XCD', label: 'East Caribbean Dollar (XCD)' },
  { value: 'XDR', label: 'Special Drawing Rights (XDR)' },
  { value: 'XOF', label: 'West African CFA Franc (XOF)' },
  { value: 'XPF', label: 'CFP Franc (XPF)' },
  { value: 'YER', label: 'Yemeni Rial (YER)' },
  { value: 'ZAR', label: 'South African Rand (ZAR)' },
  { value: 'ZMW', label: 'Zambian Kwacha (ZMW)' },
  { value: 'ZWL', label: 'Zimbabwean Dollar (ZWL)' },
];


const fieldMapping: { [key: string]: string } = {
  CompanyName: "Company Name",
  Website: "Web site",
  Groups: "Groups",
  MobileNo: "Mobile No",
  CompanyType: 'Companytype',
  Address: "Address",
  Currency: "Currency",
  ZipCode: "Zip Code",
  State: "State",
  City: "City",
  Providers: "Providers",
  Country: "Country",
  FirstName: 'First Name',
  LastName: 'Last Name',
  Position: 'Position',
  Email: 'Email',
  Phone: 'Phone',
  Direction: 'Direction',
  Password: 'Password',
  Group: "Group",

};

const EditClientadmin: React.FC = () => {
  const navigate = useNavigate();
  const [companyType, setCompanyType] = useState<string[]>([]);
  const [initialAudit, setInitialAudit] = useState<any>({})
  const [initialValues, setInitialValues] = useState<FormValues>({
    Website: '',
    Groups: '',
    MobileNo: '',
    CompanyName: '',
    State: '',
    Address: [''],
    City: '',
    Currency: 'USD',
    ZipCode: '',
    Country: 'US',
    FirstName: '',
    LastName: '',
    Companytype: [''],
    Position: '',
    Email: '',
    Phone: '',
    Direction: '',
    Password: '',
    Group: [{ providerId: '', }],
    Providers: [{ firstName: '', middleName: '', lastName: '', providerId: '', providertaxId: '' }],
    allocation_vendor_name: []
  });

  const dispatch = useAppDispatch()
  const [refId, setRefId] = useState<string>('');
  const [Id, setId] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [vendorOptions, setVendorOptions] = useState<VendorOption[]>([]);


  useEffect(() => {
    dispatch(get_vendorname())
      .then((response) => {
        const res1 = response.payload;
        const vendorname = res1.map((item: any) => ({
          label: item.CompanyName,
          value: item.id,
        }));
        const vendor = vendorname.slice().sort((a: any, b: any) =>
          a.label.localeCompare(b.label)
        );
        setVendorOptions(vendor);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dispatch]);


  useEffect(() => {
    setCompanyType(initialValues.Companytype); // Ensure Companytype is an array
  }, [initialValues]);




  const Username = localStorage.getItem('UserName') || '';


  const ProviderSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    providerId: Yup.string().required('Provider ID is required'),
  });

  const FORM_VALIDATION = Yup.object().shape({
    CompanyName: Yup.string().required("Company Name is required"),

    // Website: Yup.string().required("Website is required"),
    // Address: Yup.string().required("Address is required"),
    Address: Yup.array().of(Yup.string().required("Address is required")),
    City: Yup.string().required("City is required"),
    Companytype: Yup.array().of(Yup.string()).required("Companytype is required"),
    State: Yup.string().required("State is required"),
    ZipCode: Yup.string().required("Zip Code is required"),
    FirstName: Yup.string().required("First Name is required"),
    Phone: Yup.string().matches(/^[0-9]+$/, "Phone number must contain only numbers").required("Phone number is required"),

    // LastName: Yup.string().required("Last Name is required"),
    Email: Yup.string().required("Email is required"),
    // Direction: Yup.string().required("Direction is required"),
    // Password: Yup.string().required("Password is required"),
    allocation_vendor_name: Yup.array().min(1, 'At least one vendor must be selected'),
    // Providers: Yup.array().of(ProviderSchema).required('At least one provider is required').min(1, 'At least one Provider is required'),
  });


  const handleInput = (event: any) => {
    event.target.value = event.target.value.replace(/[^A-Za-z]/g, '');
  };



  const getUserData = async (id: string) => {
    const EncParams = AesEncrypt(JSON.stringify(id));
    const response = await fetch(webservice + 'api/ClientManagement/GetAdminClientData', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then((response) => response.json());
    const TempData = AesDecrypt(response);
    return TempData;
  };


  useEffect(() => {
    const fetchData = async () => {
      const data = localStorage.getItem('ClientEdit');
      if (data != null) {
        const res = await getUserData(data);
        if (res.length > 0) {
          setId(res[0].id);
          setRefId(res[0].RefId);
          updateDetails(res);
        }
      }
    };

    fetchData();
  }, [vendorOptions]);

  const updateDetails = async (data: any[]) => {
    const Contact_Details = data[0].Contact_Details
    const selectedOptions = vendorOptions.filter((option: any) =>
      data[0].allocation_vendor_id.includes(option.value)
    );
    if (selectedOptions.length !== 0) {
      setInitialValues({
        Website: data[0].Website,
        Groups: data[0].Groups,
        MobileNo: data[0].MobileNo,
        CompanyName: data[0].CompanyName,
        State: data[0].State,
        Address: data[0].Address ? data[0].Address : [''],
        City: data[0].City,
        Currency: data[0].Currency,
        ZipCode: data[0].ZipCode,
        Country: data[0].Country,
        allocation_vendor_name: selectedOptions,
        Companytype: data[0].Companytype,
        FirstName: Contact_Details.FirstName,
        LastName: Contact_Details.LastName,
        Position: Contact_Details.Position,
        Email: Contact_Details.Email,
        Phone: Contact_Details.Phone,
        Direction: Contact_Details.Direction,
        Password: Contact_Details.Password,

        Providers: data[0].Providers.length > 0 ? data[0].Providers.map((provider: any) => ({
          firstName: provider.firstName,
          middleName: provider.middleName,
          lastName: provider.lastName,
          providerId: provider.providerId,
          providertaxId: provider.providertaxId
        })) : [{ firstName: '', middleName: '', lastName: '', providerId: '', providertaxId: '' }],
        Group: data[0].Group.length > 0 ? data[0].Group.map((Group: any) => ({
          providerId: Group.providerId,
        })) : [{ providerId: '' }],


      });
    }

    setInitialAudit({
      Website: data[0].Website,
      Groups: data[0].Groups,
      MobileNo: data[0].MobileNo,
      CompanyName: data[0].CompanyName,
      State: data[0].State,
      Address: data[0].Address ? data[0].Address : [''],
      City: data[0].City,
      Currency: data[0].Currency,
      ZipCode: data[0].ZipCode,
      Country: data[0].Country,
      Companytype: data[0].Companytype,
      allocation_vendor_name: data[0].allocation_vendor_name,
      FirstName: Contact_Details.FirstName,
      LastName: Contact_Details.LastName,
      Position: Contact_Details.Position,
      Email: Contact_Details.Email,
      Phone: Contact_Details.Phone,
      Direction: Contact_Details.Direction,
      Password: Contact_Details.Password,

      Group: data[0].Group.length > 0 ? data[0].Group.map((Group: any) => ({
        providerId: Group.providerId,
      })) : [{ providerId: '' }],

      Providers: data[0].Providers.length > 0 ? data[0].Providers.map((provider: any) => ({
        firstName: provider.firstName,
        middleName: provider.middleName,
        lastName: provider.lastName,
        providerId: provider.providerId,
        providertaxId: provider.providertaxId
      })) : [{ firstName: '', middleName: '', lastName: '', providerId: '', providertaxId: '' }],

    });


  };



  const updatedata = async (data: FormValues, id: string) => {
    const EncParams = AesEncrypt(JSON.stringify({ data, id }));
    const res = await fetch(webservice + 'api/ClientManagement/EditClient', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(response => response.json());
    return AesDecrypt(res);
  };

  const updateAuditlog = async (data: any) => {
    data.Userid = localStorage.id;
    data.ParentUserId = localStorage.ParentUserId;
    const EncParams = AesEncrypt(JSON.stringify(data));
    const response = await fetch(webservice + 'api/ClientManagement/EditAuditLogData', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(response => response.json());
    return AesDecrypt(response);
  };


  const handleSubmit = async (data: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    // if (isSubmitting) {
    //   return;
    // }
    // setIsSubmitting(true);
    const mapvendorValues = data.allocation_vendor_name.map((option: any) => option.label);

    const allocation_vendor_id = data.allocation_vendor_name.map((option: any) => option.value);

    data.allocation_vendor_name = mapvendorValues

    let updatedFields: Array<{ field: string, before: any, after: any }> = [];

    for (const key in data) {
      const label = fieldMapping[key] || key;

      // Check if the field is Providers and handle array comparison
      if (key === 'Providers' && Array.isArray(data[key])) {
        const beforeProviders = initialAudit[key as keyof FormValues] as Array<string>;
        const afterProviders = data[key as keyof FormValues] as Array<string>;

        // Check if arrays are of the same length and contain the same elements
        const isEqual = JSON.stringify(beforeProviders) === JSON.stringify(afterProviders);

        if (!isEqual) {
          updatedFields.push({
            field: label,
            before: beforeProviders,
            after: afterProviders,
          });
        }
      } else if (key === 'allocation_vendor_name' && Array.isArray(data[key])) {
        // Handle allocation_vendor_name separately if it's also an array field
        const beforeVendors = initialAudit[key as keyof FormValues] as Array<string>;
        const afterVendors = data[key as keyof FormValues] as Array<string>;

        // Check if arrays are of the same length and contain the same elements
        const isEqual = JSON.stringify(beforeVendors) === JSON.stringify(afterVendors);

        if (!isEqual) {
          updatedFields.push({
            field: label,
            before: beforeVendors,
            after: afterVendors,
          });
        }
      } else {
        // For non-array fields, compare directly
        const beforeValue = initialAudit ? initialAudit[key as keyof FormValues] : undefined;
        const afterValue = data[key as keyof FormValues];

        if (beforeValue !== afterValue) {
          updatedFields.push({
            field: label,
            before: beforeValue,
            after: afterValue,
          });
        }
      }
    }

    if (allocation_vendor_id) {
      (data as any).allocation_vendor_id = allocation_vendor_id
    }

    try {
      const ref = await updatedata(data, Id);

      if (ref === 'Username is already taken.') {
        Swal.fire({
          title: 'Warning',
          text: 'Username already exists.',
          icon: 'warning',
          customClass: {},
          showClass: {
            popup: 'animate__animated animate__shakeX',
          },
          buttonsStyling: true,
          allowOutsideClick: false,
        });
      } else if (ref === 'email is already taken.') {
        Swal.fire({
          title: 'Warning',
          text: 'Email already exists.',
          icon: 'warning',
          customClass: {},
          showClass: {
            popup: 'animate__animated animate__shakeX',
          },
          buttonsStyling: true,
          allowOutsideClick: false,
        });
      } else if (ref === 'Username and email are already taken.') {
        Swal.fire({
          title: 'Warning',
          text: 'Username and email already exist.',
          icon: 'warning',
          customClass: {},
          showClass: {
            popup: 'animate__animated animate__shakeX',
          },
          buttonsStyling: true,
          allowOutsideClick: false,
        });
      } else if (ref === 'Edited Successfully') {
        const response = await updateAuditlog({ updatedFields, refId, Username });
        if (response === 'Updated Successfully') {
          Swal.fire({
            title: 'Insurance Verification Platform',
            html: '<span style="color: green;">User Updated successfully!</span>',
            icon: 'success',
            customClass: {},
            showClass: {
              popup: 'animate__animated animate__flipInX',
            },
            buttonsStyling: true,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.value) {
              navigate('/client');
            }
          });
        }
      } else {
        throw new Error('Response data is missing or undefined');
      }
    } catch (error) {
      console.error('Error updating Client:', error);
      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: 'An error occurred while updating the Client.',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-danger',
        },
      });
    } finally {
      setSubmitting(false);
    }
  };



  const handleNumbers = (event: any) => {
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
  }

  const handleIndividualChange = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
    const { checked } = e.target;
    let newCompanyType = [...companyType];

    if (checked) {
      // Add 'Individual' to the array
      if (!newCompanyType.includes('Individual')) {
        newCompanyType = [...newCompanyType, 'Individual'];
      }
    } else {
      // Remove 'Individual' from the array
      newCompanyType = newCompanyType.filter(type => type !== 'Individual');
    }

    setCompanyType(newCompanyType);
    setFieldValue('Companytype', newCompanyType);
  };

  const handleGroupChange = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
    const { checked } = e.target;
    let newCompanyType = [...companyType];

    if (checked) {
      // Add 'Group' to the array
      if (!newCompanyType.includes('Group')) {
        newCompanyType = [...newCompanyType, 'Group'];
      }
    } else {
      // Remove 'Group' from the array
      newCompanyType = newCompanyType.filter(type => type !== 'Group');
    }

    setCompanyType(newCompanyType);
    setFieldValue('Companytype', newCompanyType);
  };

  return (
    <>
      <Breadcrumbs mainTitle='Edit Client' parent='Client Management' title='Edit Client' to={`/client`} />
      <Fragment>
        <CardBody className='py-2 my-25'>
          <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={FORM_VALIDATION} onSubmit={handleSubmit}>
            {({ values, setFieldValue }) => (
              <Form className="form-horizontal">
                <Card>
                  <CardHeader style={{ backgroundColor: '#B4B4B3' }}>
                    <CardTitle tag="h3">Client Details</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={4} xs={12}>
                        <FormGroup>
                          <Label check> Company Name <span style={{ color: 'red' }}> * </span></Label>
                          <Field name="CompanyName" type="text" className="form-control" placeholder="Enter the Company Name" />
                          <ErrorMessage name="CompanyName" component="span" className="text-danger" />
                        </FormGroup>
                      </Col>
                      <Col md={4} xs={12}>
                        <FormGroup>
                          <Label check> Website </Label>
                          <Field name="Website" type="text" className="form-control" placeholder="Enter the Website" />
                          {/* <ErrorMessage name="Website" component="span" className="text-danger" /> */}
                        </FormGroup>
                      </Col>
                      <Col md={4} xs={12}>
                        <FormGroup>
                          <Label check>
                            Company Type <span style={{ color: 'red' }}> * </span>
                          </Label>
                          <Col md={6} xs={12}>
                            <Label check>
                              <input
                                type="checkbox"
                                name="Individual"
                                value="Individual"
                                checked={companyType.includes('Individual')}
                                onChange={(e) => handleIndividualChange(e, setFieldValue)}
                              />
                              &nbsp;Individual
                            </Label>
                          </Col>
                          <ErrorMessage name="Companytype" component="span" className="text-danger" />
                            <Col md={3} xs={12}>
                              <Label check>
                                <input
                                  type="checkbox"
                                  name="Companytype"
                                  value="Group"
                                  checked={companyType.includes('Group')}
                                  onChange={(e) => handleGroupChange(e, setFieldValue)}
                                />
                                &nbsp;Group
                              </Label>
                            </Col>
                            <ErrorMessage name="Companytype" component="span" className="text-danger" />
                        </FormGroup>
                      </Col>
                      {companyType.includes('Individual') && (
                        <Col md="12">
                          <FieldArray name="Providers">
                            {({ push, remove }) => (
                              <div>
                                <Label for="Providers">
                                  <strong>Provider Details</strong>
                                </Label>
                                <><br /></>
                                {values.Providers.map((provider, index) => (
                                  <Row key={index} className="d-flex mb-3">
                                    <div className="d-flex">
                                      {companyType.includes('Individual') && (
                                        <>
                                          <Col md={2} xs={12} className="me-4">
                                            <Label check>
                                              First Name
                                            </Label>
                                            <Field
                                              name={`Providers[${index}].firstName`}
                                              type="text"
                                              onInput={handleInput}
                                              className="form-control"
                                              placeholder="First Name"
                                            />
                                            <ErrorMessage
                                              name={`Providers[${index}].firstName`}
                                              component="span"
                                              className="text-danger"
                                            />
                                          </Col>
                                          <Col md={2} xs={12} className="me-4">
                                            <Label check> Middle Name </Label>
                                            <Field
                                              name={`Providers[${index}].middleName`}
                                              type="text"
                                              onInput={handleInput}
                                              className="form-control"
                                              placeholder="Middle Name"
                                            />
                                          </Col>
                                          <Col md={2} xs={12} className="me-4">
                                            <Label check>
                                              Last Name
                                            </Label>
                                            <Field
                                              name={`Providers[${index}].lastName`}
                                              type="text"
                                              onInput={handleInput}
                                              className="form-control"
                                              placeholder="Last Name"
                                            />
                                            <ErrorMessage
                                              name={`Providers[${index}].lastName`}
                                              component="span"
                                              className="text-danger"
                                            />
                                          </Col>
                                          <Col md={2} xs={12} className="me-4">
                                            <Label check> Provider Tax Id </Label>
                                            <Field
                                              name={`Providers[${index}].providertaxId`}
                                              type="text"
                                              className="form-control"
                                              placeholder="Provider Tax ID"
                                            />
                                          </Col>
                                        </>
                                      )}
                                      {/* Always render Provider NPI Id */}
                                      <Col md={2} xs={12} className="me-4">
                                        <Label check>
                                          Provider NPI Id
                                        </Label>
                                        <Field
                                          name={`Providers[${index}].providerId`}
                                          type="text"
                                          className="form-control"
                                          placeholder="Provider ID"
                                        />
                                        <ErrorMessage
                                          name={`Providers[${index}].providerId`}
                                          component="span"
                                          className="text-danger"
                                        />
                                      </Col>
                                      <br />

                                      <div className="ms-2">
                                        <i
                                          className="fa fa-plus-square cursor-pointer"
                                          onClick={() =>
                                            push({
                                              firstName: '',
                                              middleName: '',
                                              lastName: '',
                                              providerId: '',
                                              providertaxId: ''
                                            })
                                          }
                                          style={{ fontSize: '20px', marginTop: '35px' }} // Adjust margin to align with the input field
                                        ></i>
                                      </div>
                                      {values.Providers.length > 1 && (
                                        <div className="ms-2">
                                          <i
                                            className="fa fa-times-circle-o cursor-pointer"
                                            onClick={() => remove(index)}
                                            style={{ fontSize: '22px', marginTop: '35px', color: 'red' }} // Apply similar styles as the plus icon
                                          ></i>
                                        </div>

                                      )}

                                    </div>
                                  </Row>
                                ))}
                              </div>
                            )}
                          </FieldArray>
                        </Col>


                      )}

                      {companyType.includes('Group') && (
                        <Col md="12">
                          <FieldArray name="Group">
                            {({ push, remove }) => (
                              <div>
                                <Label for="Group">
                                  <strong>Provider Details</strong>
                                </Label>
                                <><br /></>
                                {values.Group.map((Group, index) => (
                                  <Row key={index} className="d-flex mb-3">
                                    <div className="d-flex">
                                      {/* Render First Name, Middle Name, Last Name, and Provider Tax Id fields only if companyType includes 'Individual' */}

                                      {/* Always render Provider NPI Id */}
                                      <Col md={2} xs={12} className="me-4">
                                        <Label check>
                                          Provider NPI Id
                                        </Label>
                                        <Field
                                          name={`Group[${index}].providerId`}
                                          type="text"
                                          className="form-control"
                                          placeholder="Provider ID"
                                        />
                                        <ErrorMessage
                                          name={`Group[${index}].providerId`}
                                          component="span"
                                          className="text-danger"
                                        />
                                      </Col>
                                      <br />

                                      <div className="ms-2">
                                        <i
                                          className="fa fa-plus-square cursor-pointer"
                                          onClick={() =>
                                            push({
                                              providerId: '',
                                            })
                                          }
                                          style={{ fontSize: '20px', marginTop: '35px' }} // Adjust margin to align with the input field
                                        ></i>
                                      </div>
                                      {values.Group.length > 1 && (
                                        <div className="ms-2">
                                          <i
                                            className="fa fa-times-circle-o cursor-pointer"
                                            onClick={() => remove(index)}
                                            style={{ fontSize: '22px', marginTop: '35px', color: 'red' }} // Apply similar styles as the plus icon
                                          ></i>
                                        </div>

                                      )}

                                    </div>
                                  </Row>
                                ))}
                              </div>
                            )}
                          </FieldArray>
                        </Col>


                      )}


                      {companyType.includes('Group') && (
                        <Col md={4} xs={12}>
                          <FormGroup>
                            <Label check> Groups  <span>   (Mention if it’s a Group of Company)</span></Label>
                            <Field name="Groups" type="text" className="form-control" placeholder="Enter the Groups" />
                            <ErrorMessage name="Groups" component="span" className="text-danger" />
                          </FormGroup>
                        </Col>
                      )}
                      <Col md={4} xs={12}>
                        <FormGroup>
                          <Label check>Currency</Label>
                          <Select
                            name="Currency"
                            options={CurrencyOptions}
                            value={CurrencyOptions.find(option => option.value === values.Currency || '')}
                            onChange={option => setFieldValue('Currency', option?.value)}
                            className="react-select"
                            classNamePrefix="select"
                          />
                          <ErrorMessage name="Currency" component="span" className="text-danger" />
                        </FormGroup>
                      </Col>
                      <Col md={4} xs={12}>
                        <FormGroup>
                          <Label check> Mobile No </Label>
                          <Field name="MobileNo" type="text" className="form-control" onInput={handleNumbers} placeholder="Enter the Mobile No." />
                          <ErrorMessage name="MobileNo" component="span" className="text-danger" />
                        </FormGroup>
                      </Col>
                      {/* <Col md={4} xs={12}>
                        <FormGroup>
                          <Label check> Address <span style={{ color: 'red' }}> * </span></Label>
                          <Field name="Address" type="text" className="form-control" placeholder="Enter the Address" />
                          <ErrorMessage name="Address" component="span" className="text-danger" />
                        </FormGroup>
                      </Col> */}
                      <Col md={4} xs={12}>
                        <FormGroup>
                          <Label check> City <span style={{ color: 'red' }}> * </span></Label>
                          <InputGroup>
                            <Field name="City" placeholder="Enter the City" type='text' className="form-control" />
                          </InputGroup>
                          <ErrorMessage name="City" component="span" className="text-danger" />
                        </FormGroup>
                      </Col>
                      <Col md={4} xs={12}>
                        <FormGroup>
                          <Label check>State <span style={{ color: 'red' }}> * </span></Label>
                          <Field name="State" type="text" className="form-control" placeholder="Enter the State" />
                          <ErrorMessage name="State" component="span" className="text-danger" />
                        </FormGroup>
                      </Col>
                      <Col md={4} xs={12}>
                        <FormGroup>
                          <Label check>Zip Code <span style={{ color: 'red' }}> * </span></Label>
                          <Field name="ZipCode" onInput={handleNumbers} inputMode="numeric" pattern="[0-9]*"
                            maxLength="7" type="text" className="form-control" placeholder="Enter the Zip Code" />
                          <ErrorMessage name="ZipCode" component="span" className="text-danger" />
                        </FormGroup>
                      </Col>
                      <Col md={4} xs={12}>
                        <FormGroup>
                          <Label check>Country</Label>
                          <Select
                            name="Country"
                            options={CountryOptions}
                            value={CountryOptions.find(option => option.value === values.Country || '')}
                            onChange={(option) => setFieldValue('Country', option?.value)}
                            className="react-select"
                            classNamePrefix="select"
                          />
                          <ErrorMessage name="Country" component="span" className="text-danger" />
                        </FormGroup>
                      </Col>
                      
                      <Col md={4} xs={12}>
                        <FormGroup>
                          <Label check>Map Vendor <span style={{ color: 'red' }}> * </span></Label>
                          <Select
                            name="allocation_vendor_name"
                            value={values.allocation_vendor_name || []}
                            options={vendorOptions}
                            isMulti
                            onChange={(options: any) => {
                              setFieldValue('allocation_vendor_name', options);
                            }}
                            className="react-select"
                            classNamePrefix="select"
                          />
                          <ErrorMessage name="allocation_vendor_name" component="span" className="text-danger" />
                        </FormGroup>
                      </Col>

                      <Col md={3} xs={12}>
                        <FieldArray name="Address">
                          {({ push, remove }) => (
                            <>
                              {values.Address.map((address: string, index: number) => (
                                <div key={index} className="mb-3">
                                  <Label>Address<span style={{ color: 'red' }}>*</span></Label>
                                  <div className="d-flex align-items-center">
                                    <Field
                                      as="textarea"
                                      name={`Address[${index}]`}
                                      className="form-control"
                                      placeholder=""
                                    />
                                    <Plus size={16} color="green" className="ms-2 cursor-pointer" onClick={() => push('')} />
                                    {values.Address.length > 1 && (
                                      <X size={16} color="red" className="ms-2 cursor-pointer" onClick={() => remove(index)} />
                                    )}
                                  </div>
                                  <ErrorMessage name={`Address[${index}]`} component="span" className="text-danger" />
                                </div>
                              ))}
                            </>
                          )}
                        </FieldArray>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader style={{ backgroundColor: '#B4B4B3' }}>
                    <CardTitle tag="h3">Contact Details</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={4} xs={12}>
                        <FormGroup>
                          <Label check> First Name<span style={{ color: 'red' }}> * </span> </Label>
                          <Field name="FirstName" type="text" onInput={handleInput} className="form-control" placeholder="Enter the First Name" />
                          <ErrorMessage name="FirstName" component="span" className="text-danger" />
                        </FormGroup>
                      </Col>
                      <Col md={4} xs={12}>
                        <FormGroup>
                          <Label check> Last Name </Label>
                          <Field name="LastName" type="text" onInput={handleInput} className="form-control" placeholder="Enter the Last Name" />
                          {/* <ErrorMessage name="LastName" component="span" className="text-danger" /> */}
                        </FormGroup>
                      </Col>
                      <Col md={4} xs={12}>
                        <FormGroup>
                          <Label check> Position </Label>
                          <Field name="Position" type="text" className="form-control" placeholder="Enter the Position" />
                          <ErrorMessage name="Position" component="span" className="text-danger" />
                        </FormGroup>
                      </Col>
                      <Col md={4} xs={12}>
                        <FormGroup>
                          <Label check>Email <span style={{ color: 'red' }}> * </span></Label>
                          <Field name="Email" type="text" className="form-control" placeholder="Enter the Email" />
                          <ErrorMessage name="Email" component="span" className="text-danger" />
                        </FormGroup>
                      </Col>
                      <Col md={4} xs={12}>
                        <FormGroup>
                          <Label check> Phone<span style={{ color: 'red' }}> * </span> </Label>
                          <Field
                            name="Phone"
                            type="text"
                            className="form-control"
                            onInput={handleNumbers}
                            placeholder="Enter the Phone No."
                            inputMode="numeric"
                            pattern="[0-9]*"
                            maxLength="16"
                          />
                          <ErrorMessage name="Phone" component="span" className="text-danger" />
                        </FormGroup>
                      </Col>
                      {/* <Col md={4} xs={12}>
                        <FormGroup>
                          <Label check> Direction</Label>
                          <InputGroup>
                            <Field name="Direction" placeholder="Enter the Direction" type='text' className="form-control" />
                          </InputGroup>
                          <ErrorMessage name="Direction" component="span" className="text-danger" />
                        </FormGroup>
                      </Col> */}
                      {/* <Col md={4} xs={12}>
                        <FormGroup>
                          <Label check> Password <span style={{ color: 'red' }}> * </span></Label>
                          <InputGroup>
                            <Field name="Password" placeholder="Enter the Password" type={showPassword ? 'text' : 'password'} className="form-control" />
                            <InputGroupText className="cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? <EyeOff size={14} /> : <Eye size={14} />}
                            </InputGroupText>
                          </InputGroup>
                          <ErrorMessage name="Password" component="span" className="text-danger" />
                        </FormGroup>
                      </Col> */}
                    </Row>
                  </CardBody>
                </Card>
                {/* {companyType && ( 
                <Card>
                  <CardHeader style={{ backgroundColor: '#B4B4B3' }}>
                    <CardTitle tag="h3">Provider Details</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                        <Col md='12'>
                          <FieldArray name="Providers">
                            {({ push, remove }) => (
                              <div>
                                <Label for='Providers'>Provider Details <span style={{ color: 'red' }}> * </span></Label>
                                <br/>
                                {values.Providers.map((provider, index) => (
                                  <Row key={index} className="d-flex mb-3">
                                    <div className="d-flex">
                                      <Col md={2} xs={12}>
                                      <Label check> First Name <span style={{ color: 'red' }}> * </span></Label>
                                        <Field name={`Providers[${index}].firstName`} type="text" className="form-control" placeholder="First Name" />
                                        <ErrorMessage name={`Providers[${index}].firstName`} component="span" className="text-danger" />
                                      </Col>
                                      <Col md={1} xs={12}></Col>
                                      <Col md={2} xs={12}>
                                      <Label check> Middle Name <span style={{ color: 'red' }}> * </span></Label>
                                        <Field name={`Providers[${index}].middleName`} type="text" className="form-control " placeholder="Middle Name" />
                                        <ErrorMessage name={`Providers[${index}].middleName`} component="span" className="text-danger" />
                                      </Col>
                                      <Col md={1} xs={12}></Col>
                                      <Col md={2} xs={12}>
                                      <Label check> Last Name <span style={{ color: 'red' }}> * </span></Label>
                                        <Field name={`Providers[${index}].lastName`} type="text" className="form-control " placeholder="Last Name" />
                                        <ErrorMessage name={`Providers[${index}].lastName`} component="span" className="text-danger" />
                                      </Col>
                                      <Col md={1} xs={12}></Col>
                                      <Col md={2} xs={12}>
                                      <Label check> Provider Id <span style={{ color: 'red' }}> * </span></Label>
                                        <Field name={`Providers[${index}].providerId`} type="text" className="form-control" placeholder="Provider ID" />
                                        <ErrorMessage name={`Providers[${index}].providerId`} component="span" className="text-danger" />
                                      </Col>
                                      <Col md={1} xs={12}>
                                      <br/>

                                      <div className="d-flex  justify-content-center">
                                        {companyType === 'Group' && (
                                          <Plus size={24} color="green" className="cursor-pointer" onClick={() => push({ firstName: '', middleName: '', lastName: '', providerId: '' })} />
                                        )}
                                        {values.Providers.length > 1 && (
                                          <X size={24} color="red" className="ms-2 cursor-pointer" onClick={() => remove(index)} />
                                        )}
                                      </div>
                                    </Col>
                                    </div>
                                  </Row>
                                ))}
                              </div>
                            )}
                          </FieldArray>
                        </Col>
                    </Row>
                  </CardBody>
                </Card>
                 )} */}
                <Row>
                  <Col>
                    <div className="text-end">
                      <Link to={`/client`}>
                        <Button color="danger" className="me-3">Cancel</Button>
                      </Link>
                      <Button type="submit" color="success" className="me-3">Update</Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Fragment>
    </>
  );
};

export default EditClientadmin;
